<template>
  <section class="section-wrapper">
    <h3 class="text__title-large text-center section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">경기 안내</h3>

    <template v-if="isLoading"> 로딩 중.. </template>
    <template v-else>
      <span style="flex-direction: row; display: flex; justify-content: center; align-items: center; margin-bottom: 20px !important; margin-top: 20px">
        <div class="pdf-page-button" :style="{ visibility: page <= 1 ? 'hidden' : 'visible' }">
          <v-icon @click="page > 1 ? page-- : () => {}">{{ mdiChevronLeft }}</v-icon>
        </div>
        <div style="margin-left: 10px; margin-right: 10px">{{ page }} / {{ numberOfPages }}</div>
        <div class="pdf-page-button" :style="{ visibility: page >= numberOfPages ? 'hidden' : 'visible' }">
          <v-icon :disabled="page >= numberOfPages" @click="page++">{{ mdiChevronRight }}</v-icon>
        </div>
      </span>
    </template>
    <vue-pdf-embed
      :scale="10"
      :disable-annotation-layer="true"
      :page="page"
      width="300"
      :source="require('@/assets/leaflet_compressed.pdf')"
      style="margin-bottom: 20px"
      @loaded="finishedLoaded"
      @rendered="finishedRendered"
    />

    <contest-document-buttons :is-mobile-ui="isMobileUi" />
  </section>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed';
import { ref } from 'vue';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import ContestDocumentButtons from '@/containers/Contest/ContestDocumentButtons';

export default {
  name: 'ContestInfoDetail',
  components: { VuePdfEmbed, ContestDocumentButtons },
  props: {
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const page = ref(1);
    const isLoading = ref(true);
    const numberOfPages = ref(1);

    const finishedLoaded = (e) => {
      numberOfPages.value = e?._pdfInfo?.numPages;
    };

    const finishedRendered = () => {
      isLoading.value = false;
    };

    return { page, isLoading, numberOfPages, finishedLoaded, finishedRendered, mdiChevronLeft, mdiChevronRight };
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }
}

:deep(canvas) {
  width: 100% !important;
  height: calc(100% * 1.414) !important;
}

.pdf-page-button {
  border-radius: 5px;
  border-width: 1px;
  border-color: #272f3e;
  border-style: solid;
  padding: 10px;
}

.section-button {
  display: block;
  width: fit-content;
  text-decoration: none;
  margin: 24px 0;
  padding: 12px 10px 14px 18px;

  @include sm-and-down {
    span {
      @include spreadTypoMap($button-small);
    }
  }

  svg {
    margin-left: 4px;
  }

  @include md-and-up {
    margin: 48px 0;
    padding: 16px 16px 16px 24px;

    svg {
      margin-left: 8px;
    }
  }
}
</style>
