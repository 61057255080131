<template>
  <div class="button-group" :class="{ ['button-group__mobile']: isMobileUi }" v-bind="attrs">
    <a
      :href="require('@/assets/2022_contest_rules_1125.pdf')"
      download="2022 국제한국미용페스티벌_IKBF 2022_경기_규정"
      class="button-large-line section-button"
    >
      <span
        >경기 규정 다운로드 <icon-base><chevron-right-arrow /></icon-base
      ></span>
    </a>
    <a :href="require('@/assets/2022_contest_schedule.pdf')" download="전체 경기 일정" class="button-large-line section-button">
      <span
        >경기일정 참가 안내 및 일정 다운받기<icon-base><chevron-right-arrow /></icon-base
      ></span>
    </a>
  </div>
</template>

<script>
import ChevronRightArrow from '@/icons/ChevronRightArrow';

export default {
  name: 'ContestDocumentButtons',
  components: { ChevronRightArrow },
  props: {
    isMobileUi: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    return { attrs };
  },
};
</script>

<style lang="scss" scoped>
.button-group {
  display: flex;
  flex-direction: column;

  &__mobile {
    justify-content: center;
    flex-direction: column;
  }

  a {
    text-decoration: none;
    margin-bottom: 20px;
  }
}
</style>
