<template>
  <page doc-title="대회 수정" title-size="medium" main-width="4">
    <template #main-section="{ isMobileUi }">
      <circular-loader v-if="!isMounted" />
      <contest-edit-form
        v-else
        :is-mobile-ui="isMobileUi"
        :is-disabled="isDisabled"
        :title="title"
        :button-text="buttonText"
        :default-player-info="playerInfo"
        :default-recommender-info="recommenderInfo"
        :is-student-form="registStatus.isStudentForm"
        :is-independent-business-form="registStatus.isIndependentBusinessForm"
        :submit-handler="onSubmitHandler"
        :complete-handler="onCompleteHandler"
      />
    </template>
  </page>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import { modifyPlayer } from '@/apis/player';
import { useContestStore, useBranchStore } from '@/stores';

import Page from '@/components/Page';
import ContestEditForm from '@/containers/ContestEditForm/ContestEditForm';

import { CONTEST_REGISTER_TYPE, PLAYER_INFO_TITLE, CONTEST_REGISTER_TYPE_PASCAL } from '@/constants/contest';
import { convertDataForApi, validateFormData } from '@/utils/contest';
import { errorToast, successToast } from '@/utils/toast';
import { isEmpty } from 'lodash-es';

export default {
  name: 'ContestPlayerUpdate',
  isMobileUi: {
    type: Boolean,
    default: false,
  },
  components: {
    Page,
    ContestEditForm,
  },
  setup() {
    const router = useRouter();
    const contestStore = useContestStore();
    const branchStore = useBranchStore();

    const isDisabled = ref(true);
    const isMounted = ref(false);

    const registStatus = ref({
      isStudentForm: false,
      isIndependentBusinessForm: false,
    });
    const buttonText = ref('잘못된 접근입니다');

    const playerInfo = ref({
      associationName: '',
      name: '',
      birthday: {
        year: '',
        month: '',
        day: '',
      },
      phone: '',
      businessName: '',
      address: '',
      addressDetail: '',
      zip: '',
      contestItemIds: [],
      note: '',
      contestRegisterType: '',
      associationType: '',
      regionName: '',
      branchOfficeId: '',
      contestId: 1, // 이거 나중에 변경 가능하게 하기
      playerFiles: [],
      attachmentList: [],
      password: '',
    });

    const recommenderInfo = ref({
      associationName: '',
      branchOfficeId: '',
      name: '',
      phone: '',
      associationType: '',
      regionName: '',
    });

    const title = ref('');

    const initData = async () => {
      await contestStore.fetchContestPreiodStatus(playerInfo.value.contestId);
      isMounted.value = true;
    };

    onMounted(() => {
      initData();

      if (isEmpty(contestStore.playerInfo)) {
        errorToast('잘못된 접근입니다.');
        router.push('/contest/1');
        return;
      }

      const branches = branchStore.branchInfoArray;
      playerInfo.value = contestStore.playerInfo;
      playerInfo.value.regionName = branches.find((v) => v.id === playerInfo.value.branchOfficeId)?.regionName || '';

      recommenderInfo.value = contestStore.recommenderInfo;
      recommenderInfo.value.regionName = branches.find((v) => v.id === recommenderInfo.value.branchOfficeId)?.regionName || '';

      registStatus.value.isStudentForm = playerInfo.value.contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.STUDENT;
      registStatus.value.isIndependentBusinessForm = playerInfo.value.contestRegisterType === CONTEST_REGISTER_TYPE_PASCAL.INDEPENDENT_BUSINESS;

      title.value = `대회 접수 수정 (${PLAYER_INFO_TITLE[playerInfo.value.contestRegisterType]})`;
      buttonText.value = '수정';
      isDisabled.value = false;
    });

    const onCompleteHandler = () => {
      successToast('접수 내역 수정이 완료되었습니다.');
      router.push('/contest/1');
    };

    const checkIsUpdatableContest = () => {
      const isEditableContest = contestStore.contestPeriodStatus.isEditableDay || contestStore.contestPeriodStatus.isRunContest;

      if (!isEditableContest) {
        errorToast('선수 정보는 경기시작 7일 전, 경기 진행중에만 수정할 수 있습니다.');
        return false;
      }

      return true;
    };

    const onSubmitHandler = async (playerInfo, recommender, isLogin) => {
      const isEditable = checkIsUpdatableContest();

      if (!isEditable) return;
      if (isLogin) {
        delete playerInfo.password;
      }

      const validInfo = validateFormData(playerInfo, recommender, isLogin, CONTEST_REGISTER_TYPE[playerInfo.contestRegisterType]);

      /** !isValid 면 인풋을 입력하라는 모달 표시 */
      if (!validInfo.isValid) {
        return validInfo;
      }

      const { convertedPlayerInfo, convertedRecommender } = convertDataForApi(playerInfo, recommender);

      // console.log(JSON.stringify(convertedPlayerInfo.playerFiles), JSON.stringify(convertedPlayerInfo.attachmentList));

      /**
       * !! FIXME: - fix reason : playerInfo 의 attachmentList / playerFiles 의 sync가 맞지 않음
       * attachmentList 의 list는 등록된 file은 가지고 오지 못한다.
       * playerFiles 는 등록된 file도 가지고 있는다.
       */
      // console.log('raw', convertedPlayerInfo.playerFiles, convertedPlayerInfo.attachmentList);
      convertedPlayerInfo.playerFiles = convertedPlayerInfo.playerFiles.filter((e) => !e.url);

      const form = { ...convertedPlayerInfo, recommender: convertedRecommender };
      // console.log(form);
      const formData = new FormData();

      formData.append('request', new Blob([JSON.stringify(form)], { type: 'application/json' }));
      form.attachmentList.forEach((item) => {
        formData.append('attachmentList', item);
      });

      await modifyPlayer(playerInfo.id, formData);

      return { isValid: true };
    };

    return { playerInfo, recommenderInfo, title, buttonText, isDisabled, isMounted, registStatus, onSubmitHandler, onCompleteHandler };
  },
};
</script>
