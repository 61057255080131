import { defineStore } from 'pinia';
import { isEmpty } from 'lodash-es';

import { useLoadingStore } from '@/stores/index';

import { getAllContest, getAllContestItemByContestId, getContestPeriodStatus } from '@/apis/contest';
import { getPlayerDetail } from '@/apis/player';
import { CLASSIFICATION, CONTEST, EVENT, SECTOR, FILE_CATEGORY_CAMEL_CASE } from '@/constants/contest';

const STORE_ID = 'contest';
const SET_DEFAULT_CONTEST_INFOS = 'SET_DEFAULT_CONTEST_INFOS';

export const CONTEST_ACTION_NAME = {
  [SET_DEFAULT_CONTEST_INFOS]: `${STORE_ID}/setDefaultContestInfos`,
};

export default defineStore({
  id: 'contest',
  state: () => ({
    [CONTEST]: [],
    [CLASSIFICATION]: [],
    [SECTOR]: [],
    [EVENT]: [],
    targetContestId: '',
    playerInfo: {},
    recommenderInfo: {},
    contestItems: [],
    contestPeriodStatus: {
      isEditableDay: false,
      isRunContest: false,
      isRunContestApplication: false,
    },
  }),
  getters: {
    getContests: (state) => state[CONTEST],
    getClassification: (state) => state[CLASSIFICATION],
    getContestItems: (state) => state.contestItems,
  },
  actions: {
    async fetchUpdatePlayerInfo(contestId, playerName, phoneNumber, password) {
      const playerInfo = await getPlayerDetail(contestId, playerName, phoneNumber, password);

      if (!isEmpty(playerInfo)) {
        const {
          id,
          name,
          birthDay,
          phone,
          address,
          addressDetail,
          zip,
          businessName,
          note,
          contestRegisterType,
          associationType,
          branchOfficeId,
          branchOfficeName,
          associationName,
          contestId,
          recommender,
          playerFiles,
          playerMirrorMappings,
        } = playerInfo;
        const [year, month, day] = birthDay.split('-');

        const displayedFilesInfo = playerFiles.reduce((obj, prev) => {
          obj[FILE_CATEGORY_CAMEL_CASE[prev.category]] = {
            label: prev.name,
            url: prev.url,
          };

          return obj;
        }, {});

        this.playerInfo = {
          id,
          name,
          birthday: {
            year,
            month,
            day,
          },
          phone,
          address,
          addressDetail,
          zip,
          businessName,
          contestRegisterType,
          associationType,
          branchOfficeId,
          branchOfficeName,
          associationName,
          contestId,
          playerFiles,
          attachmentList: [],
          note,
          contestItemIds: playerMirrorMappings.map((v) => v.contestItemId),
          password: '',
          ...displayedFilesInfo,
        };
        this.recommenderInfo = recommender;
        return true;
      }

      return false;
    },
    async fetchAllContestItems(contestId, contestRegisterType) {
      const classifications = await getAllContestItemByContestId(contestId, contestRegisterType);

      if (Array.isArray(classifications) && classifications.length > 0) {
        const contestItems =
          classifications?.map((classification) => classification.subContestItems.map((sector) => sector.subContestItems.map((v) => v)).flat()).flat() ?? [];

        this.contestItems = contestItems;
        return classifications;
      }

      return [];
    },
    async setDefaultContestInfos() {
      const loadingStore = useLoadingStore();
      loadingStore.setLoadingState(CONTEST_ACTION_NAME.SET_DEFAULT_CONTEST_INFOS, true);

      const contests = await getAllContest();

      if (!isEmpty(contests)) {
        this[CONTEST] = contests;
      }

      loadingStore.setLoadingState(CONTEST_ACTION_NAME.SET_DEFAULT_CONTEST_INFOS, false);
    },
    async fetchContestPreiodStatus(contestId) {
      const contestPeriodStatus = await getContestPeriodStatus(contestId);

      this.contestPeriodStatus = contestPeriodStatus;
    },
    setTargetContestId(contestId) {
      this.targetContestId = contestId;
    },
    getTargetContestInfo() {
      if (!this.targetContestId) return {};

      return this[CONTEST].find(({ id }) => id === this.targetContestId) || {};
    },
    // async fetchContestItemByParentId(parentId, type) {
    //   const contestItem = (await getContestItemByParentId(parentId)) || [];
    //
    //   const defaultContestOption = { id: 'unSelect', label: '종목 선택' };
    //
    //   const convertedItems = contestItem.reduce(
    //     (acc, { displayStatus, id, name }) => {
    //       if (displayStatus !== 'SHOW') return acc;
    //       return [...acc, { id, label: name }];
    //     },
    //     [defaultContestOption],
    //   );
    //
    //   this[type] = convertedItems;
    //
    //   return convertedItems;
    // },
  },
});
