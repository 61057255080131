<template>
  <section class="section-wrapper">
    <h3 class="text__title-large text-center section-title" :style="`display: ${isMobileUi ? 'none' : 'block'}`">온라인 선수 접수</h3>
    <p :class="{ 'text__body-large': !isMobileUi, 'text__body-xsmall': isMobileUi }" class="section-description text-center">
      온라인 접수는 선수 출전비 입금 후 아래 선수접수 클릭하여 순서대로 입력하시면 됩니다.<br />학생부는 개별적으로 온라인 접수가 가능합니다.<br />
      <!--      일반부는 지회 실무자들이 출전신청서를 취합하여 지회로 부여할 인증번호(추후 공문으로 안내)를 넣으면 승인되어 접수하시면 됩니다.-->
    </p>
    <div class="card-grid-layout">
      <template v-for="card in cards" :key="card.title">
        <router-link :to="card.to" class="category-card" @click="card.onClick">
          <div class="category-card__content">
            <h4>{{ card.title }}</h4>
            <span v-for="(desc, descIdx) in card.desc.split('\n')" :key="descIdx" class="cards__desc">
              {{ desc }}
            </span>
          </div>

          <icon-base icon-color="white"><right-arrow /></icon-base>
        </router-link>
      </template>
    </div>

    <contest-document-buttons :is-mobile-ui="isMobileUi" style="margin-top: 20px" />
  </section>
</template>

<script>
import { onMounted, ref } from 'vue';

import RightArrow from '@/icons/RightArrow';
import { CONTEST_REGISTER_TYPE } from '@/constants/contest';
import { useUserStore } from '@/stores';
import { infoToast } from '@/utils/toast';

import ContestDocumentButtons from '@/containers/Contest/ContestDocumentButtons';

export default {
  name: 'ContestRegisterInfo',
  components: { RightArrow, ContestDocumentButtons },
  props: {
    isMobileUi: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const userStore = useUserStore();

    const onClickDisabledCard = () => {
      infoToast('소상공인부, 일반부는 로그인 후 지원 가능합니다.');
    };

    const cards = ref([
      {
        title: '소상공인부',
        desc: '미용사 면허증 또는 자격증 소지자\n(미용업 영업주)',
        to: `/contest-form/regist/${CONTEST_REGISTER_TYPE.INDEPENDENT_BUSINESS}`,
        onClick: onClickDisabledCard,
      },
      { title: '학생부', desc: '미용관련 고등학교 및 대학,\n대학교 재학생', to: `/contest-form/regist/${CONTEST_REGISTER_TYPE.STUDENT}`, onClick: () => {} },
      { title: '일반', desc: '미용사 면허증 또는 자격증 소지자', to: `/contest-form/regist/${CONTEST_REGISTER_TYPE.GENERAL}`, onClick: onClickDisabledCard },
    ]);

    onMounted(() => {
      if (!userStore.checkLoginStatus()) {
        cards.value[0].to = '#';
        cards.value[2].to = '#';
      } else {
        cards.value[0].onClick = () => {};
        cards.value[2].onClick = () => {};
      }
    });

    return { cards };
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  margin-top: 24px;
  padding: 0 20px;

  @include md-and-up {
    margin-top: 48px;
    padding: 0 20px;
  }
}

.section-title {
  color: $gray-80;
}

.section-description {
  color: $gray-80;
  @include md-and-up {
    margin-top: 48px;
  }
}

.card-grid-layout {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  @include md-and-up {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 48px;
  }
}

.category-card {
  width: 100%;
  height: auto;
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  border-radius: 8px;

  background: rgba(24, 30, 45, 0.8);
  border: 1px solid $black;
  backdrop-filter: blur(10px);

  @include lg-and-up {
    height: 324px;
    flex-direction: column;
    padding: 24px;
  }

  @include md {
    height: 300px;
  }

  &__content {
    @include md-and-up {
      align-self: flex-start;
    }
    h4 {
      @include spreadTypoMap($title-xsmall);
      color: white;

      @include md-and-up {
        @include spreadTypoMap($title-medium);
        color: white;
      }
    }

    .cards__desc {
      display: block;
      @include spreadTypoMap($body-xsmall);
      color: white;
      word-break: keep-all;

      @include md-and-up {
        @include spreadTypoMap($body-large);
        color: white;
      }
    }
  }

  svg {
    display: block;
    width: 24px;
    height: 24px;

    @include md-and-up {
      align-self: flex-end;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &-translucent {
    background: rgba(24, 30, 45, 0.5);
    backdrop-filter: blur(20px);
    border-color: $gray-70;

    @include md-and-up {
      border-color: $black;
    }
  }
}

.section-button {
  display: block;
  width: fit-content;
  text-decoration: none;
  margin: 24px 0;
  padding: 12px 10px 14px 18px;

  @include sm-and-down {
    span {
      @include spreadTypoMap($button-small);
    }
  }

  svg {
    margin-left: 4px;
  }

  @include md-and-up {
    margin: 48px 0;
    padding: 16px 16px 16px 24px;

    svg {
      margin-left: 8px;
    }
  }
}
</style>
